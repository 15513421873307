import AttendanceHistory from "./table";
import { Link } from "react-router-dom";
import { getAuthHeaders, getMethodApiCall } from "src/services/api";
import { useEffect, useState } from "react";
import moment from "moment";
import ReactPaginationStyle from "src/components/ReactPagination";
import { useTranslation } from 'react-i18next';

const AttendanceHistoryLayout = () => {
    const { t } = useTranslation();
    const [page, setPage] = useState(1)
    const [limit, setLimit] = useState(10)
    const [data, setData] = useState({})
    const [startDate, setStartDate] = useState(moment().subtract(3, 'months').format('YYYY-MM-DD'))
    const [endDate, setEndDate] = useState(moment().add(1, "day").format('YYYY-MM-DD'))

    // const getTraineeAttendance = async () => {
    //     console.log("heheh");
    //     let url = `/v1/api/trainee/attendance/get?page=${page}&limit=${limit}&startDate=${startDate}&endDate=${endDate}`
    //     let res = await getMethodApiCall(url, getAuthHeaders())
    //     if (res?.status === 200) {
    //         setData(res?.data)
    //     }
    // }

    const getTraineeAttendance = async () => {
        const storedData = localStorage.getItem("loginedUser");
        let traineeId; 
if (storedData) {
    const parsedData = JSON.parse(storedData);
     traineeId = parsedData?.trainee?.id;
}
        // Build the base URL
        let url = `/v1/api/trainee/attendance/get?page=${page}&limit=${limit}&startDate=${startDate}&endDate=${endDate}`;
    
        if (traineeId) {
            url += `&trainee_id=${traineeId}`;
        }
    
        // Make the API call
        let res = await getMethodApiCall(url, getAuthHeaders());
        if (res?.status === 200) {
            setData(res?.data);
        }
    };

   

    useEffect(() => {
        getTraineeAttendance()
    }, [page, limit, startDate, endDate])


    const handlePagination = async (page) => {
        setPage(page?.selected + 1);
    };

  

    return (
        <div className="container px-2 mx-auto my-5 min-h-96 ">
        <div className="mb-2 flex items-center justify-between gap-2">
            <p className="m-0 text-[16px] font-semibold text-primary">
                {t('attendance.title')}
            </p>
            <Link
                to='/Trainee-details'
                className="border text-white bg-primary border-primary p-2 text-sm font-normal focus:ring-offset-blue-200 transition ease-in duration-200 text-center shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 rounded-md cursor-pointer select-none">
                {t('attendance.recordAttendance')}
            </Link>
            
        </div>
        <div className="rounded-md bg-white shadow p-2 ">
            <div
                className="mb-2 grid grid-cols-2 gap-2 lg:grid-cols-6 md:grid-cols-4 sm:grid-cols-2">
                <input
                    value={startDate}
                    onChange={(e) => setStartDate(e.target.value)}
                    className="border placeholder:text-gray-300 rounded-md px-3 py-2 text-sm w-full outline-none "
                    type="date"
                    placeholder={t('attendance.startDate')}
                />
                <input
                    value={endDate}
                    onChange={(e) => setEndDate(e.target.value)}
                    className="border placeholder:text-gray-300 rounded-md px-3 py-2 text-sm w-full outline-none "
                    type="date"
                    placeholder={t('attendance.endDate')}
                />
                <select
                    onChange={(e) => setLimit(e.target.value)}
                    className="border rounded-md px-3 py-2 text-sm w-full outline-none "
                >
                    <option>{t('attendance.rows')}</option>
                    <option value={10}>{t('attendance.rows10')}</option>
                    <option value={25}>{t('attendance.rows25')}</option>
                    <option value={50}>{t('attendance.rows50')}</option>
                    <option value={100}>{t('attendance.rows100')}</option>
                </select>
                <div className="flex justify-center items-center space-x-2">
                    <ReactPaginationStyle
                        total={data?.meta?.totalRecords}
                        currentPage={page}
                        handlePagination={handlePagination}
                        limit={limit}
                    />
                </div>
            </div>
            <AttendanceHistory data={data} />
        </div>
    </div>
    )
}

export default AttendanceHistoryLayout;