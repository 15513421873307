

export const tradeList = [
    "Turner",
    "Electrician",
    "Welder",
    "Machinist",
    "Mechanic Electric Vehicle",
    "Warehouse Technician",
    "Electronic Mechanic",
    "Mechanic Motor Vehicle",
    "Textile Wet Processing Technician",
    "Physiotherapy Technician",
    "Health Sanitary Inspector",
    "Plumber",
    "Fitter",
].sort()


export const industryList = [
    "Electromech Power (I) Pvt. Ltd.",
    "Mantra E-Bikes",
    "A.B.C Motors",
    "Mohan Fourwheel Pvt. Ltd.",
    "Shiv Tractors",
    "Haryana Roadways, Ambala",
    "Sankei Giken India Pvt. Ltd., Bawal",
    "Charvi Motor Pvt. Ltd., Tepla (Ambala Cantt.)",
    "VIPIN MOTORS (P) LTD.",
    "Haryana Roadways, Jhajjar",
    "Candour Technology",
    "Haryana Roadways Jhajjar",
    "Haryana Roadways, Panipat",
    "The Panipat Co-Operative Sugar Mill Ltd.",
    "Radha Doors, Unit-II,NH-52, VPO Behbalpur(Hisar)",
    "Silver Star, Laxmi Vihar, Barwala",
    "Ceen Sports Wear & Garments, Barwala",
    "Public Health Engg. Department, Barwala",
    "OSAW Agro Industries Pvt. Ltd.",
    "Samrithi Motors Pvt. Ltd., Ambala",
    "Naraingarh Sugar Mill Ltd.",
    "Sujal Exim India Pvt. Ltd.",
    "Sunita Under Gaments",
    "Shiv Shankar Industries",
    "Kundu Engineering Works",
    "Dharmesh Textiles Limited",
    "Daniels Furniture",
    "Chauhan Electronics",
    "Haryana Roadways, Bhiwani",
    "Haryana Roadways, Faridabad",
    "Alfa Aircon",
    "Dangi Industries Pvt. Ltd.",
    "Imperial Auto Industries Limited",
    "Hettich India Ltd.",
    "Voltas Limited",
    "JBM Auto Limited",
    "Orient Electric Limited",
    "Star Wire Limited",
    "Shivalik Power Control Limited",
    "Escorts Kubota Limited",
    "Akriti Apparels Pvt. Ltd.",
    "PEE EMPRO Exports Pvt. Ltd.",
    "Surya Shakti Vessels Pvt. Ltd.",
    "RIBA Textiles Limited, Chidana, Gohana",
    "UHBVN, Gohana Division",
    "Ch. Devi Lal Co-Operative Sugar Mill, Gohana",
    "Haldiram Mfg. Co. Pvt. Ltd.",
    "Prem Motors Pvt. Ltd.",
    "Voltas Ltd.",
    "Triple Play Interactive Pvt. Ltd.",
    "HPL Electric and Power",
    "HPL Electric and Power",
    "Munjal Showa Ltd.",
    "Jay Bharat Maruti Ltd.",
    "Moksha Welness Pvt. Ltd.",
    "Khandewala Krishi Udyog, Hansi",
    "Grover Petro & Auto Industries, Hisar",
    "Mohan Earth Movers LLP, Rohtak",
    "SnPC Machines Pvt. Ltd., Sonepat",
    "Alienskart Web Pvt. Ltd., Sonepat",
    "Universal Precision Screws, Rohtak",
    "Haryana Roadways, Palwal",
    "Talbros Engineering Limited",
    "The Palwal Coop. Sugar Mills Ltd.",
    "Panchmukhi Automobile, Hisar",
    "Jindal Stainless Ltd., Hisar",
    "Jindal Supreme (India) Pvt. Ltd., Hisar",
    "Ashoka Engineers",
    "Orion Motors Pvt. Ltd., Hisar",
    "Alpha Technology",
    "Haryana Roadways, Jind",
    "Shona Agri Solutions, Jind",
    "The Jind Co-Op. SugarMill Ltd.",
    "Uniparels Pvt. Ltd.",
    "Farmtrac Tannu Automobile",
    "Skyvue Fenestration",
    "Panchkula Steel",
    "Raja Gears Pvt. Ltd.",
    "Milestone Gears Pvt. Ltd.",
    "Haryana Roadways, Panchkula",
    "Jindal Engg. Works, Kaithal",
    "The Kaithal Co-Op Sugar Mills Ltd., Kaithal",
    "Indo Farms Implements, HSIIDC, Karnal",
    "Partap Iron Works, HSIIDC, Karnal",
    "J.K. Pumps (P) Ltd., Karnal",
    "Goodrich Carbohydrates Ltd., Karnal",
    "Pair Agroking Pvt. Ltd., Karnal",
    "Gorsi Air Conditioner, Karnal",
    "Joshi Enterprises, Karnal",
    "Piara Singh & Sons, Karnal",
    "Krishna Agro Industries, Karnal",
    "Green TVS Autos",
    "Nirmal Motors, Karnal",
    "PP Automotive, Karnal",
    "Samta Hyundai, Karnal",
    "Bansal Motors, Karnal",
    "Best Cars, Karnal",
    "Dwarka Dhish Associates, Karnal",
    "Ahana Decore Pvt. Ltd., Karnal",
    "Haryana Forest Development Corp. Ltd., Karnal",
    "Star Automobile, Meerut Road, Karnal",
    "Chaman Lal Setia Esports Ltd.",
    "Haryana Roadways, Karnal",
    "Mansa Solar Energy, Karnal",
    "Pawar Technologies, Karnal",
    "Simtach Automation, Karnal",
    "Gauri Shakti Fashions, Karnal",
    "Central Tool Room, Ludhian (Extension Centre, Karnal)",
    "Sagar Sports",
    "Jagmohan Automotives Pvt. Ltd.",
    "Malik Generator",
    "Superhub Komponents Pvt. Ltd.",
    "SnPC Machines Pvt. Ltd.",
    "Ajit Industries Pvt. Ltd., Sonipat",
    "Bhagwan Automatics Pvt. Ltd.",
    "Caparo Maruti Ltd., Bawal",
    "INCOOS Woodtech",
    "Ganpati Auto Traders",
    "Vishwakarma Automobiles",
    "The Meham Co-Op. Sugar Mills Ltd.",
    "Visaka Industries Limited",
    "Ultratech Cement Limited.",
    "D.P. Industries",
    "Sahyog Engg. Industries, Rohtak",
    "Glob Tech Process Equipment",
    "Microtech Engineers",
    "IECS Computer Education (Management Infotech System), Hisar",
    "Datta Agro Works, Hansi",
    "Haryana Roadways, Narnaul",
    "Deep Sports Wear Narwana",
    "Haryana Roadways, Sirsa",
    "Shivani Locks Pvt. Ltd., Prithla",
    "Venus Industrial Corporation Pvt. Ltd., Prithla",
    "Wide India Industries Prithla",
    "Hotel Pallavi",
    "About U Salon",
    "Metro Motors (P) Ltd.",
    "Dee Tex Engineering Works",
    "Riviera Home Furnishings Pvt. Ltd.",
    "Varun Beverages Ltd.",
    "UPS Instruments Pvt. Ltd.",
    "Anurag Care, Pehowa",
    "Kulwant Electronics, Pehowa",
    "Kurukshetra Panorma & Science Centre, Kurukshetra",
    "Haryana Roadways, Pehowa",
    "Shri Guru Gobind Singh Institute (Hartron), Pehowa",
    "Jeyaar Polymer Pvt. Ltd.",
    "Sainsons Paper Ind. (Pvt.) Ltd.",
    "Haryana Roadways, Charkhi Dadri",
    "Design 'O' Creation",
    "Chadha Sales Pvt. Ltd.",
    "Arjan Impex Pvt. Ltd.",
    "Indian Nippon Electrical Pvt. Ltd.",
    "Hero Moto Corp., Dharuhera",
    "Kansai Nerolac Paints Ltd.",
    "V.M Electronics",
    "Peehu Electronics",
    "The Haryana Co-operative Sugar Mills Bhali Anandpur Rohtak",
    "Universal Precision Screws",
    "Haryana Tourism Corporation Limited Tilyar, Rohtak",
    "Haryana Tourism Corporation Limited Myna, Rohtak",
    "M.G. Motors",
    "Kamal Coach Body Maker",
    "Public Health Engineering Department, Rohtak",
    "Chanderpur Works Private Limited",
    "HSF Food Pro Tech Pvt. Ltd."
].sort()

export const itiNameList = [
    "GITI Hisar",
    "GITI (W) Hisar",
    "GITI Hansi",
    "GITI Barwala",
    "GITI Adampur",
    "GITI Nalwa",
    "GITI Sirsa",
    "GITI (W) Sirsa",
    "GITI Nathusari Chopta",
    "GITI Bhoria Khera",
    "GITI Bhirdana",
    "GITI Tohana",
    "GITI Jind",
    "GITI Narwana",
    "GITI Ambala City",
    "GITI Barara at Holi",
    "GITI Bharanpur",
    "GITI Kurukshetra",
    "GITI Pehowa",
    "GITI Kalka at Bitna",
    "GITI Panchkula",
    "GITI Mustafabad",
    "GITI Sadhaura",
    "GITI Yamuna Nagar",
    "GITI Faridabad",
    "GITI (W) Faridabad",
    "GITI Fatehpur Biloch",
    "GITI Ujjina",
    "GITI Hathin",
    "GITI Palwal",
    "GITI Gurugram",
    "GITI (W) Gurugram",
    "GITI Sohna",
    "GITI JIM, Uncha Majra",
    "GITI Narnaul",
    "GITI Kundmanethi",
    "GITI Rewari",
    "GITI Tankri",
    "GITI Karnal",
    "GITI (W) Karnal",
    "GITI Kaithal",
    "GITI Pundari",
    "GITI Bapoli",
    "GITI Panipat",
    "GITI Bhiwani",
    "GITI Tosham",
    "GITI Devrala",
    "GITI Rawaidhi",
    "GITI (W) Charkhi Dadri",
    "GITI Chhara",
    "GITI Asaudah",
    "GITI Bahadurgarh",
    "GITI Jhajjar",
    "GITI Matanhail",
    "GITI Rohtak",
    "GITI Hassangarh",
    "GITI Kiloi",
    "GITI Madina",
    "GITI Meham",
    "GITI Butana",
    "GITI Gohana",
    "GITI Kharkhoda",
    "GITI Rajlugarhi",
    "GITI Sonipat",
    "GITI (W) Sonipat"
].sort()

export const haryanaCities = [
    "Ambala",
    "Bhiwani",
    "Charkhi Dadri",
    "Faridabad",
    "Gurugram",
    "Hisar",
    "Panipat",
    "Rohtak",
    "Karnal",
    "Yamunanagar",
    "Sonipat",
    "Panchkula"
];

export const haryanaDistricts = [
    "Ambala",
    "Hisar",
    "Sirsa",
    "Fatehabad",
    "Jind",
    "Ambala",
    "Kurukshetra",
    "Panchkula",
    "Yamuna Nagar",
    "Faridabad",
    "Nuh",
    "Palwal",
    "Gurugram",
    "Mahendergarh",
    "Rewari",
    "Karnal",
    "Kaithal",
    "Panipat",
    "Bhiwani",
    "Charkhi Dadri",
    "Jhajjar",
    "Rohtak",
    "Sonipat"
];

export const tradeUnits = [
    "Bapoli",
    "Barana",
    "Fatehpur Biloch",
    "Panipat",
    "Sohna"
].sort()