import moment from "moment";
import React, { useState } from "react";
import ButtonIcon from "src/components/custom/ButtonIcon";
import Modal from "src/components/modal";
import { getAuthHeaders, postMethodApiCall } from "src/services/api";
import { useTranslation } from 'react-i18next';
import { toast } from "react-toastify";

const AttendanceTable = ({ data }) => {
    const { t } = useTranslation();  // Access the translation function
    const [openModal, setOpenModal] = useState(false)
    const [diff, setDiff] = useState({})
    const [userData, setUserdata] = useState({})
    const [targetLocation, setTargetLocation] = useState({
        lat: null,
        lng: null,
      });
    const [currentLocation, setCurrentLocation] = useState({
        lat: null,
        lng: null,
    });
    function isDifferenceMoreThan8Hours(date1) {
        const startDate = new Date(date1);
        const endDate = new Date();
        const diffInMilliseconds = endDate - startDate;
        if (diffInMilliseconds < 0) {
            return {
                error: "Start date is in the future compared to the current time",
                success: false,
            };
        }
        const totalHours = Math.floor(diffInMilliseconds / (1000 * 60 * 60));
        const days = Math.floor(totalHours / 24);
        const hours = totalHours % 24;
        const isMoreThan8Hours = totalHours > 8;

        return {
            isMoreThan8Hours,
            days,
            hours,
            success: true,
        };
    }


    function isDifferenceMoreThan8HoursBool(date1) {
        const startDate = new Date(date1);
        const endDate = new Date();
        const diffInMilliseconds = endDate - startDate;

        if (diffInMilliseconds < 0) {
            // If the start date is in the future, return false
            return false;
        }

        const totalHours = Math.floor(diffInMilliseconds / (1000 * 60 * 60));
        return totalHours > 8;
    }


    const handlePopUp = async(data) => {
        setOpenModal(!openModal)
        let timeDiff = isDifferenceMoreThan8Hours(data.date);
        
        setCurrentLocation({lat:data.latitude,lng:data.longitude})

       await getCoordinatesFromMapbox(data.name_of_industry_partner)
        setDiff(timeDiff);
        setUserdata(data)
    }
    function deg2rad(deg) {
        return deg * (Math.PI / 180);
      }

    function calculateDistance(lat1, lon1, lat2, lon2) {
        const R = 6371; // Radius of the Earth in kilometers
        const dLat = deg2rad(lat2 - lat1);
        const dLon = deg2rad(lon2 - lon1);
        const a =
          Math.sin(dLat / 2) * Math.sin(dLat / 2) +
          Math.cos(deg2rad(lat1)) *
            Math.cos(deg2rad(lat2)) *
            Math.sin(dLon / 2) *
            Math.sin(dLon / 2);
        const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
        const distance = R * c; // Distance in kilometers
        // console.log(distance);
        if(distance <50){
          return true
        }else{
          return false
        }
      }
    const apiKey = "AIzaSyC2Mkdfi-8r579NqAFwczjluMnNM6Xf6Ko";
    const getCoordinatesFromMapbox = async (address) => {
        try {
          const geocodeUrl = `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
            address
          )}&key=${apiKey}`;
    
          fetch(geocodeUrl)
            .then((response) => {
              if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
              }
              return response.json();
            })
            .then((data) => {

              if (data.status === "OK") {
                const location = data.results[0].geometry.location;
                setTargetLocation({ lat: location.lat, lng: location.lng });
              } else {
                console.error("Geocoding error:", data.status, data.error_message);
              }
            })
            .catch((error) => {
              console.error("Fetch error:", error);
            });
        } catch (err) {
          console.error("Error fetching location from Mapbox:", err);
          return null;
        }
      };


      const logout = async () => {
        let data = {
            trainee_id: userData.trainee_id,
            attendance_id: userData.id,
        };
    
        let url = `/v1/api/logout-time`;
    
        // Initialize distance
        let distance = 0;
    
        // Fetch current user location
        await new Promise((resolve, reject) => {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    const userLat = position.coords.latitude;
                    const userLng = position.coords.longitude;
                    
                    // Calculate distance between current location and target location
                    distance = calculateDistance(
                        currentLocation.lat,
                        currentLocation.lng,
                        userLat,
                        userLng
                    );
                    resolve(); // Indicate successful fetching
                },
                (error) => {
                    console.error("Error fetching user location:", error.message);
    
                    switch (error.code) {
                        case error.PERMISSION_DENIED:
                            console.error("User denied the request for Geolocation.");
                            break;
                        case error.POSITION_UNAVAILABLE:
                            console.error("Location information is unavailable.");
                            break;
                        case error.TIMEOUT:
                            console.error("The request to get user location timed out.");
                            break;
                        case error.UNKNOWN_ERROR:
                            console.error("An unknown error occurred.");
                            break;
                    }
                    reject(); // Indicate failure
                }
            );
        });
    
        // console.log("Calculated Distance:", distance);
    
        if (distance ) {
            try {
                // Await the response from the API call
                let res = await postMethodApiCall(url, getAuthHeaders(), data);
    
                // Only reload the page if the API call is successful
                if (res && res.status === 200) {
                    await toast.success("Logout Successfully");
                    window.location.reload();
                } else {
                    console.error('API call failed', res);
                }
            } catch (error) {
                console.error('Error during API call:', error);
            }
        } else {
            await toast.error("Please Mark Your Attendance within 50 meter radius");
        }
    
        setOpenModal(false);
    };
    

    return (
        <div className="w-full h-full  overflow-x-auto">
           


            <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
  <thead className="text-xs text-gray-700 uppercase bg-gray-50">
    <tr className="bg-gray-100 border-b border-gray-200">
      <th className="px-4 py-2 text-left text-sm font-semibold text-gray-700">
        {t('attendance.table.number')}
      </th>
      <th className="px-4 py-2 text-left text-sm font-semibold text-gray-700">
        {t('attendance.table.date')}
      </th>
      <th className="px-4 py-2 text-left text-sm font-semibold text-gray-700">
        {t('attendance.table.attendance')}
      </th>
      <th className="px-4 py-2 text-left text-sm font-semibold text-gray-700">
        {t('attendance.table.industry')}
      </th>
      <th className="px-4 py-2 text-left text-sm font-semibold text-gray-700">
        {t('attendance.table.trade')}
      </th>
      <th className="px-4 py-2 text-left text-sm font-semibold text-gray-700">
        {t('attendance.table.iti')}
      </th>
      <th className="px-4 py-2 text-left text-sm font-semibold text-gray-700">
        {t('attendance.table.logoutTime')}
      </th>
    </tr>
  </thead>
  <tbody>
    {data?.data?.length > 0 ? (
      data?.data?.map((item, index) => (
        <tr
          key={item?.id}
          className="border-b hover:bg-gray-50 transition-colors"
        >
          <td className="px-4 py-2 text-sm text-gray-800">
            {index + 1}
          </td>
          <td className="px-4 py-2 text-sm text-gray-800 whitespace-nowrap">
            {moment(item?.date).format('DD/MM/YYYY HH:mm A')}
          </td>
          <td className="px-4 py-2 text-sm text-gray-800">
            {item?.status}
          </td>
          <td className="px-4 py-2 text-sm text-gray-800 overflow-hidden text-ellipsis whitespace-nowrap">
            {item?.name_of_industry_partner}
          </td>
          <td className="px-4 py-2 text-sm text-gray-800 overflow-hidden text-ellipsis whitespace-nowrap">
            {item?.name_of_trade}
          </td>
          <td className="px-4 py-2 text-sm text-gray-800">
            {item?.name_of_your_ITI}
          </td>
          <td className="px-4 py-2 text-sm text-gray-800 whitespace-nowrap">
            {isDifferenceMoreThan8HoursBool(item?.date) ? (
              item?.logout_time ? (
                moment(item?.logout_time).format('DD/MM/YYYY HH:mm A')
              ) : (
                <ButtonIcon
                  className="bg-primary"
                  title={t('itiHome.logout')}
                  onClick={() => handlePopUp(item)}
                />
              )
            ) : (
              t('itiHome.Active')
            )}
          </td>
        </tr>
      ))
    ) : (
      <tr>
        <td
          colSpan="7"
          className="px-4 py-2 text-center text-sm text-gray-500"
        >
          No records found
        </td>
      </tr>
    )}
  </tbody>
</table>


            <Modal
                isOpen={openModal}
                title={'Logout'}
                className='text-danger'
                onClose={() => {
                    setOpenModal(false)
                }}
            >
                <div className="">
                    <span className="text-red-500 font-bold ">Are you sure you want to logout Days{diff.days} Hour:{diff.hours}</span>
                </div>
                <div className="flex justify-end mt-5">
                    <ButtonIcon title={'No'} onClick={() => setOpenModal(false)} />
                    <ButtonIcon disabled={!diff.isMoreThan8Hours} className={'bg-red-500 border-red-600 text-white hover:bg-red-500 '} onClick={() => logout()} title={'Yes'} />
                </div>

            </Modal>
        </div>
    );
};


export default AttendanceTable;
